import React, { useState } from "react";
import { useLocation } from "@reach/router";
import axios from "axios"; // Import Axios for making HTTP requests

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import Button from "../components/Button/Button";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import { sendMessage as apiSendMessage } from "../services/api";

import "./LegalPage.css";

const ContactUsPage = ({ pageContext }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    // Simple validation
    const newErrors = {};
    if (formData.name === "") {
      newErrors.name = "Name is required";
    }
    if (formData.email === "") {
      newErrors.email = "Email is required";
    }
    if (formData.message === "") {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        // Make a POST request to localhost API
        const response = await apiSendMessage(formData);
        console.log("Response:", response.data);
        // Clear form after successful submission
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>CONTACT US</SectionHeading>
          <form onSubmit={handleSubmit} className="form-container">
            <div style={{ maxWidth: 900 }}>
              <div className="field">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
              <div className="field">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="field">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && (
                  <div className="error">{errors.message}</div>
                )}
              </div>
              <Button onClick={() => handleSubmit()}>Submit</Button>
            </div>
          </form>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ContactUsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Contact Us | Travel-Lingual"}
      description={
        "Get in touch with Travel-Lingual. Reach out to our dedicated team for inquiries, assistance, or collaboration opportunities. We're here to make your travel experiences extraordinary. Contact us today."
      }
      pathname={location.pathname}
    />
  );
};
